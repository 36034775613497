import React from "react";
import { Box, Zoom } from "@mui/material";
import { Link } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import professionalOne from "./10.png";
import professionalTwo from "./12.png";
import professionalThree from "./11.png";
import { PrimaryButton } from "../styled-components/Buttons";

export default function HomeBanner(props) {
  const { userObject } = props;
  const { currentUser } = useAuth();

  const checkIfNurse = () => {
    // Check if the profession includes "Nurse" or "Nursing", or rpn (nursing student, nurse practioner)
    return (
      userObject?.profession?.includes("Nurse") ||
      userObject?.profession?.includes("Nursing") ||
      userObject?.profession === "Registered Practical Nurse"
    );
  };

  const checkIfPhysician = () => {
    // Check if the profession is "Physician", "Physician Assistant", "Medical Student", or "Physician Assistant Student"
    if (
      userObject?.profession === "Physician" ||
      userObject?.profession === "Physician Assistant" ||
      userObject?.profession === "Medical Student" ||
      userObject?.profession === "Physician Assistant Student"
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <Zoom in={true} style={{ transitionDelay: "100ms" }}>
        <Box>
          {!currentUser && (
            <div className="home-banner">
              <h1 style={{ margin: "0" }}>Welcome to MedEssist Hub</h1>
              <p style={{ margin: "0" }}>
                Continuing education. Clinical resources. AI.
              </p>
              <p style={{ margin: "0" }}>
                For <b>pharmacists</b>, <b>nurse practitioners</b>,{" "}
                <b>registered nurses</b>, <b>physicians</b>, and{" "}
                <b>physician assistants</b>
              </p>

              <PrimaryButton
                style={{ marginTop: "10px" }}
                component={Link}
                to={"/login?signup=true"}
              >
                Get Started - It's free!
              </PrimaryButton>

              <div className="professionals">
                <img className="professional-img" src={professionalOne} />
                <img
                  className="professional-img"
                  style={{ marginLeft: "-50px" }}
                  src={professionalThree}
                />
                <img
                  className="professional-img"
                  style={{ marginLeft: "-50px" }}
                  src={professionalTwo}
                />
              </div>
            </div>
          )}
          {userObject?.profession?.toLowerCase().includes("pharm") && (
            <div className="home-banner" style={{ background: "#7FD3CF" }}>
              <h1 style={{ margin: "0", color: "#06625D" }}>
                Welcome back to MedEssist Hub for Pharmacists
              </h1>
              <p style={{ margin: "0", color: "#06625D" }}>
                Continuing education. Clinical resources. AI.
              </p>

              <div className="professionals">
                <img className="solo-img" src={professionalOne} />
              </div>
            </div>
          )}
          {checkIfNurse() && (
            <div className="home-banner" style={{ background: "#FFB6B6" }}>
              <h1 style={{ margin: "0", color: "#A95151" }}>
                Welcome back to MedEssist Hub for Nurses
              </h1>
              <p style={{ margin: "0", color: "#A95151" }}>
                Continuing education. Clinical resources. AI.
              </p>

              <div className="professionals">
                <img className="solo-img" src={professionalThree} />
              </div>
            </div>
          )}
          {checkIfPhysician() && (
            <div className="home-banner" style={{ background: "#FFE296" }}>
              <h1 style={{ margin: "0", color: "#A8811C" }}>
                Welcome back to MedEssist Hub for Physicians
              </h1>
              <p style={{ margin: "0", color: "#A8811C" }}>
                Continuing education. Clinical resources. AI.
              </p>

              <div className="professionals">
                <img className="solo-img" src={professionalTwo} />
              </div>
            </div>
          )}
        </Box>
      </Zoom>
    </>
  );
}
